
import { Component, Ref, InjectReactive, Watch } from "vue-property-decorator";
import loading from "@/mixins/loading";
import { Form } from "element-ui";
import UploadImg from "@/components/uploadImg.vue";
import { cloneDeep, debounce, uniqWith } from "lodash";
import { IHomeInstance } from "@/types/global";

import { getMerchantDetail, saveOrUpdateMerchant, queryStore } from "@/api/prescriptionDb";

const initFormData = {
    id: "",
    name: "", // 品牌商名称
    stores: [], // 这里先放个 prop 给form 的rule使用
    contact: "", // 负责人
    phone: "",
    status: false,
    autoRegisterUser: false,
    allowUseQrcode: false,
    isShow: false,
    useCustomPres: false,
    defaultImgStatus: false,
    isShowImg: false,
    defaultImgUrl: [],
    remark: "", // 备注
    apiAppKey: "",
    apiAppSecret: "",
    logoImgUrl: [],
    companyName: "",
    backgroundImgUrl: [],
    appImgUrl: [],
}

@Component({
    components: {
        UploadImg
    }
})
export default class merchantEdit extends loading {
    @Ref("validateForm") readonly validateFormRef !: Form;
    @InjectReactive() HomeInstance !: IHomeInstance;

    merchantId = this.$route.query.merchantId || "";;
    brandName = this.$route.query.brandName || "";
    title = "编辑";

    storeInfo = []; // 关联门店数据
    saveLoading = false;
    storeSelectLoading = false;
    allStoreList = [];

    formData = {
        id: "",
        name: "", // 品牌商名称
        stores: [], // 这里先放个 prop 给form 的rule使用
        contact: "", // 负责人
        phone: "",
        status: false,
        autoRegisterUser: false,
        allowUseQrcode: false,
        isShow: false,
        useCustomPres: false,
        defaultImgStatus: false,
        isShowImg: false,
        defaultImgUrl: [],
        remark: "", // 备注
        apiAppKey: "",
        apiAppSecret: "",
        logoImgUrl: [],
        companyName: "",
        backgroundImgUrl: [],
        appImgUrl: [],
    };

    formRules = {
        name: [{ required: true, message: "请输入品牌方名称", trigger: "blur" }],
    };

    validateStoreInfo(rule, value, callback) {
        if (this.storeInfo?.length) return callback();
        return callback(new Error("请选择关联门店"))
    };

    mounted() {
        this.getMerchantDetail();
    }

    getMerchantDetail() {
        if (!this.merchantId) {
            this.title = "新增";
            return;
        }
        getMerchantDetail(this.merchantId).then((res) => {
            const { stores, status, autoRegisterUser, allowUseQrcode, isShow, useCustomPres, defaultImgStatus, isShowImg } = res.data;
            Object.assign(this.formData, { ...res.data, status: Boolean(status), autoRegisterUser: Boolean(autoRegisterUser), allowUseQrcode: Boolean(allowUseQrcode), isShow: Boolean(isShow), useCustomPres: Boolean(useCustomPres), defaultImgStatus: Boolean(defaultImgStatus), isShowImg: Boolean(isShowImg) });
            this.storeInfo = cloneDeep(stores)?.map((row) => ({ id: row.storeId, storeName: row.storeName }));
        })
    }

    getStoreRemote = debounce(function (str) {
        this.storeSelectLoading = true;
        queryStore({ storeId: str, storeName: str, pageNum: 1, pageSize: 500 }).then((res) => {
            this.storeSelectLoading = false;
            this.allStoreList = res.data;
        }).catch((err) => { });
    })


    getStoreList(str) {
        if (typeof str == "object") {
            str = "";
        }
        this.getStoreRemote(str)
    }

    setCurUser(cur, key) {
        this[key].push({ ...cur, userId: cur.id });
        const comparator = (obj1, obj2) => obj1.id === obj2.id;
        this[key] = uniqWith(this[key], comparator)
    }

    handleClose(cur, key) {
        this[key] = this[key].filter((item) => {
            return item.id != cur.id;
        });
    }

    dialogClose() {
        this.storeInfo = []; // 关联门店数据
        this.formData = cloneDeep(initFormData);
        this.validateFormRef.resetFields();
        this.HomeInstance.tabRemove("merchantEdit");
    }

    dialogConfirm() {
        if (this.formData.defaultImgStatus && this.formData.defaultImgUrl.length == 0) {
            this.$message.error("请上传默认头像");
            return;
        }
        this.validateFormRef.validate().then(() => {
            const params = {
                ...this.formData,
                stores: cloneDeep(this.storeInfo)?.map((row) => ({ storeId: row.id, storeName: row.storeName })),
                status: Number(this.formData.status),
                autoRegisterUser: Number(this.formData.autoRegisterUser),
                allowUseQrcode: Number(this.formData.allowUseQrcode),
                isShow: Number(this.formData.isShow),
                useCustomPres: Number(this.formData.useCustomPres),
                defaultImgStatus: Number(this.formData.defaultImgStatus),
                isShowImg: Number(this.formData.isShowImg),
                defaultImgUrl: this.formData.defaultImgUrl[0]?.url || "",
                logoImgUrl: this.formData.logoImgUrl[0]?.url || "",
                backgroundImgUrl: this.formData.backgroundImgUrl[0]?.url || "",
                appImgUrl: this.formData.appImgUrl[0]?.url || "",
            }
            console.log(params)
            this.saveLoading = true;
            saveOrUpdateMerchant(params).then(() => {
                this.dialogClose();
                this.$message.success("保存成功");
            }).finally(() => {
                this.saveLoading = false;
            })
        });
    }

    @Watch('storeInfo')
    validateField(val, oldVal) {
        this.validateFormRef.validateField(["storeInfo"])
    }
}
